import React from "react";
import { ReactComponent as Facebook } from '../../facebook.svg';
import { ReactComponent as Twitter } from '../../twitter.svg';
import { ReactComponent as Instagram } from '../../instagram.svg';
import { ReactComponent as Tiktok } from '../../tiktok.svg';

const Footer = ({ marketData, marketAllData }) => {
  const footerColor = marketData?.footerColor || '#ffffff';

  const FooterStyle = {
    backgroundColor: footerColor
  };

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };
  const staticImagePath = process.env.REACT_APP_IMG_URL;

  const footerImageUrl = `${staticImagePath}/${marketAllData?.image}`;

  return (
    <div>
      <footer>
        <div className="footer-wrappper" style={FooterStyle}>
          {/* Footer Start */}
          <div className="footer-area footer-padding">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-9 col-sm-8">
                  <div className="single-footer-caption mb-50">
                    <div className="single-footer-caption mb-30">
                      {/* logo */}
                      <div className="footer-logo mb-25">
                        <a href="index.html" />
                        <a href="index.html" className="tittle-logo">
                          {marketData?.mainTitle}
                        </a>
                      </div>
                      <div className="footer-tittle">
                        <div className="footer-pera">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: marketData?.footerDescription,
                            }}
                          />
                        </div>
                      </div>
                      {/* social */}
                      <div className="footer-social">
                        {marketData?.facebookUrl?.trim() && (
                          <a
                            href={marketData.facebookUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Facebook className="social-icon" />
                          </a>
                        )}
                        {marketData?.twitterUrl?.trim() && (
                          <a
                            href={marketData.twitterUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Twitter className="social-icon" />
                          </a>
                        )}
                        {marketData?.tiktokUrl?.trim() && (
                          <a
                            href={marketData.tiktokUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Tiktok className="social-icon" />
                          </a>
                        )}
                        {marketData?.instaUrl?.trim() && (
                          <a
                            href={marketData.instaUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Instagram className="social-icon" />
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                  <p className="text-white">
                        Copyright {getCurrentYear()} © {marketData?.mainTitle} All rights reserved.
                      </p>
                </div>
                <div className="col-lg-3 col-sm-4 text-center text-sm-right">
                <img
                          src={footerImageUrl}
                          alt="Footer Image"
                          className="footer-image"
                        />
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </footer>

      <style jsx>{`
        .footer-image-wrapper {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
        .footer-image {
          width: 150px; /* Adjust size as needed */
          height: 150px; /* Adjust size as needed */
          border-radius: 50%;
          object-fit: cover;
        }
      `}</style>
    </div>
  );
};

export default Footer;
