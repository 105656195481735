import React, { useContext } from "react";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import { Context } from "../context/Context";
import { useSearchParams } from "react-router-dom";
const useApiAxios = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const id = searchParams.get("id");
  const { setMarketData, marketData } = useContext(Context);
  axios.defaults.headers.common["Authorization"] =
    localStorage.getItem("token");
  axios.defaults.headers.common["Content-Type"] = "application/json";

  // You can also set headers for specific request methods
  axios.defaults.headers.get["Accept"] = "application/json";

  const {
    userData,
    setUserData,
    isAuthorized,
    setIsAuthorized,
    setIsLoading,
    openInfoSnackbar,
    openErrorSnackbar,
  } = useContext(Context);

  const ENDPOINT = process.env.REACT_APP_ENDPOINT;

  const APPLY_MARKET = "/apply/vendor/form";

  const GET_MARKET_CONTENT = "/get/market";
  const GET_MARKET_VENDOR = "/getmarketVendors";
  const GET_MARKET_VENDOR_MAP = "/getAllMapImages";
  const GET_MARKET_VENDOR_FILTER = "/get/vendor/filter";


  const CHECK_EMAIL = "/check/emailVendor"




  const applyMarketApi = async (formData) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${APPLY_MARKET}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const getMarketContentApi = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${GET_MARKET_CONTENT}?id=${id}`);
      // console.warn("IIIIIIIIIIIIIIIIO",res)
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };


  const getMarketVendorApi = async () => {
    try {
      setIsLoading(true);
      // const res = await axios.get(`${ENDPOINT}${GET_MARKET_VENDOR}`);
      const res = await axios.get(`${ENDPOINT}${GET_MARKET_VENDOR}?marketId=${id}`);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };

  const getMarketVendorMapApi = async () => {
    try {
      setIsLoading(true);
      // const res = await axios.get(`${ENDPOINT}${GET_MARKET_VENDOR}`);
      const res = await axios.get(`${ENDPOINT}${GET_MARKET_VENDOR_MAP}?marketId=${id}`);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };

  const getMarketVendorFilterApi = async (id, search,
    selectedCategoryId,
    dateId) => {
    try {
      setIsLoading(true);
      // const res = await axios.get(`${ENDPOINT}${GET_MARKET_VENDOR}`);
      const res = await axios.post(`${ENDPOINT}${GET_MARKET_VENDOR_FILTER}?marketId=${id}&search=${search}&categoryId=${selectedCategoryId}&dateId:${dateId}`);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };

  const checkVendorEmailApi = async ({ email }) => {
    try {
      const res = await axios.post(`${ENDPOINT}${CHECK_EMAIL}`, { email });
      return { error: false, data: res.data.message }
    } catch (error) {

      return { error: true, data: error.response.data.message }
    } finally {
      setIsLoading(false);
    }
  };


  const getVendorAfterDateChangeApi = async (id) => {
    try {
      setIsLoading(true)
      const res = await axios.get(`${ENDPOINT}${"/get/vendor/filter/map"}?id=${id}`,);
      return { error: false, data: res.data }
    } catch (error) {
      return { error: true, data: error.response.data.message }
    } finally {
      setIsLoading(false);
    }
  };


  return {
    applyMarketApi,
    getMarketContentApi,
    getMarketVendorApi,
    getMarketVendorMapApi,
    checkVendorEmailApi,
    getMarketVendorFilterApi,
    getVendorAfterDateChangeApi
  };
};

export default useApiAxios;
